$color-red: #f06957;
$color-green: #3eb55f;
$color-blue: #344696;
$color-white: #fff;

html,
body,
#root {
  margin: 0;
  padding: 0;

  height: 100%;
}

.g-recaptcha {
  & > div {
    margin-inline: auto;
  }
}

.grecaptcha-badge {
  visibility: hidden;
}
